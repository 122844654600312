import React from 'react';
import './Spinner.css'; // Make sure to create this CSS file

interface Props {
    message: string
  }

const Spinner: React.FC <Props>= ({message}) => {
  return (
    <div className="spinner">
      <div className="spinner-inner"></div>
      <div className="spinner-message">
      {message}
      </div>
    </div>
  );
};

export default Spinner;
