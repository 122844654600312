export class Api {
  static baseURL = "https://174.171.26.95:8000";
  // static baseURL = "https://0.0.0.0:8000";
  static token = "";

  static async get(endpoint: string, queryParameters?: any) {
    try {
      const url = new URL(endpoint, this.baseURL);

      if (queryParameters) {
        Object.keys(queryParameters).forEach((key) => {
          url.searchParams.append(key, queryParameters[key]);
        });
      }

      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        mode: "cors",
      });

      if (!response.ok) {
        console.log("Network response was not ok");
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  static async post(endpoint: string, data?: any) {
    const body = JSON.stringify(data);
    try {
      const url = new URL(endpoint, this.baseURL);

      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type" : "application/json"
        },
        body,
        mode: "cors",
      });

      if (!response.ok) {
        console.log("Network response was not ok");
        console.log(response.json());
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  static async fetchOportunitiesForSymbol(symbol: string) {
    return await this.get(`/${symbol}`);
  }

  static async scanOportunities() {
    return await this.get(`/`);
  }

  static async login(username: string, password: string) {
    return await this.post("/api/login", { username, password });
  }

  static async select(symbol: string, selected: boolean) {
    return await this.post("/api/select", { symbol, selected });
  }
}
