import React from "react";
import Modal from "react-modal";
import ResponsiveTable, { DataItem } from "./ResponsiveTable";
import Spinner from "./Spinner";

interface ModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onRequestClose: () => void;
  data: DataItem[];
  news?: string[];
}

const HistoricalModal: React.FC<ModalProps> = ({
  isLoading,
  isOpen,
  onRequestClose,
  data,
  news
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="modal-overlay"
    >
      {isLoading ? (
        <Spinner message="Loading historical data..." />
      ) : (
        <div>
          <button onClick={onRequestClose} className="modal-close-button">
            Close
          </button>
          <div className="table-container">
          <ResponsiveTable data={data} news={news}/>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default HistoricalModal;
