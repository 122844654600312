import React from "react";

export interface DataItem {
  price: number;
  date: string;
  action: string;
}

interface Props {
  data: DataItem[];
  news?: string[];
}

const ResponsiveTable: React.FC<Props> = ({ data, news }) => {
  return (
    <div>
      <div className="table-container">
        <div>
        <table className="responsive-table">
          {data.length > 0 && (
            <thead>
              <tr>
                <th>Price</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.price.toFixed(2)}</td>
                <td>{item.date ? item.date.split(" ")[0] : null}</td>
                <td>{item.action}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <div className="newsfeed">
            <h2>News Feed</h2>
            <ul>
              {news && news.map((headline, index) => (
                <li key={index}>{headline}</li>
              ))}
            </ul>
          </div>
      </div>
    </div>
  );
};

export default ResponsiveTable;
