// Login.tsx
import React, { useState } from "react";
import "../App.css";
import Spinner from "./Spinner";
import { Api } from "../networking/Api";

interface LoginProps {
  loginCallback: () => void;
}

const Login: React.FC<LoginProps> = ({loginCallback}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);

    const result = await Api.login(username, password);

    if (result && result.success === true) {
      Api.token = result.access_token;
    }
    setLoading(false);
    loginCallback();
  };

  return (
    <div className="login-component">
      <h1>Login</h1>
      <form onSubmit={handleSubmit} className="form-login">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {loading ? (
          <div>
            <Spinner message="" />
          </div>
        ) : (
          <button type="submit" disabled={loading}>
            Login
          </button>
        )}
      </form>
    </div>
  );
};

export default Login;
