import React, { useState, useEffect } from "react";
import "./App.css"; // Import the new CSS file
import { Api } from "./networking/Api";
import StockDataDisplay from "./components/StockDataDisplay";
import { StockData } from "./components/StockDataDisplay";
import Spinner from "./components/Spinner";
import HistoricalModal from "./components/HistoricalModal";
import stockGeniusLogo from "./images/stock-genius-logo.png";
import Login from "./components/Login";

function App() {
  const [symbol, setSymbol] = useState("");
  const [data, setData] = useState([]);
  const [scanData, setScanData] = useState<StockData[]>([]);
  const [filteredScanData, setFilteredScanData] = useState<StockData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFilteredScanData(scanData);
  };

  const fetchOportunitiesForSymbol = async (symbol: string) => {
    setIsLoading(true);
    openModal();
    const result = await Api.fetchOportunitiesForSymbol(symbol);
    if (result && result.length > 0) {
      setData(result);
    } else {
      setData([]);
    }
    setIsLoading(false);
  };

  const scanOportunities = async () => {
    setIsScanning(true);
    const result = await Api.scanOportunities();
    if (result && result.length > 0) {
      // console.log("RESULTS", JSON.stringify(result, null, 2));
      setScanData(result);
      setFilteredScanData(result);
    } else {
      setScanData([]);
    }
    setIsScanning(false);
  };

  const handleSubmitSymbol = (event: any) => {
    event.preventDefault();
    if (symbol) {
      fetchOportunitiesForSymbol(symbol);
    }
  };

  const handleItemClick = (selectedItem: StockData) => {
    setSymbol(selectedItem.symbol);
    fetchOportunitiesForSymbol(selectedItem.symbol);
  };

  const handleCheckboxClick = async (selectedItem: StockData, checked: boolean) => {
    await Api.select(selectedItem.symbol, checked);
    setScanData(prevScanData =>
      prevScanData.map(item =>
        item.symbol === selectedItem.symbol ? { ...item, isSelected: checked } : item
      )
    );
    setFilteredScanData(prevScanData =>
      prevScanData.map(item =>
        item.symbol === selectedItem.symbol ? { ...item, isSelected: checked } : item
      )
    );
  };

  const checkLoginState = () => {
    if (Api.token !== "") {
      setIsLoggedIn(true);
      scanOportunities();
    } else {
      setIsLoggedIn(false);
    }
  }

  useEffect(() => {
    if (symbol === "") {
      setFilteredScanData(scanData);
    } else {
      const filteredData = scanData.filter((item) => {
        return item.symbol.toLowerCase().includes(symbol.toLowerCase());
      });
      setFilteredScanData(filteredData);
    }
  }, [symbol]);

  useEffect(() => {
    checkLoginState();
  }, []);

  const loginCallBack = () => {
    checkLoginState();
  }


  return (
    <div className="App">
      <div className="main-logo">
        <img src="knife_catcher_logo_white.png"></img>
      </div>
      <p className="main-call">
        "Attempting to buy a stock that's rapidly declining in value, hoping to
        profit from a rebound."
      </p>
      {isLoggedIn ? (
        <div>
          <div>
            <form onSubmit={handleSubmitSymbol} className="form-search">
              <input
                type="text"
                value={symbol}
                onChange={(event) => setSymbol(event.target.value)}
                placeholder="Enter symbol (e.g., AAPL)"
              />
              <button type="submit">SEARCH</button>
            </form>
          </div>
          <div>
            {isScanning ? (
              <div>
                <Spinner message="Catching knives... AI magic takes time, relax and go grab a cup of coffee." />
              </div>
            ) : (
              <StockDataDisplay
                data={filteredScanData}
                onItemClick={handleItemClick}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <Login loginCallback={loginCallBack}/>
        </div>
      )}

      <div>
        <HistoricalModal
          isLoading={isLoading}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          data={data}
          news={scanData.find((item) => item.symbol === symbol)?.data}
        />
      </div>
    </div>
  );
}

export default App;
