import React, { useEffect, useState } from "react";
import { Candle } from "./StockChartTile";
import StockChartTile from "./StockChartTile";

export interface StockData {
  symbol: string;
  date: string;
  price: string;
  current_price: string;
  isMature: boolean;
  sentiment: Sentiment;
  secSentimentScore: number;
  data: string[];
  current_recommendation: string;
  tsx: string;
  isSelected: boolean;
  current_rsi: string;
  days_until_earnings: number;
  days_since_earnings: number;
  eps_sentiment: string;
  candles: Candle[];
}

interface Sentiment {
  sentimentscore: number;
  optimism: number;
  growth: number;
  challenges: string[];
}

interface Props {
  data: StockData[];
  onItemClick: (selectedItem: StockData) => void;
  onCheckboxClick: (selectedItem: StockData, checked: boolean) => void;
}

const StockDataDisplay: React.FC<Props> = ({
  data,
  onItemClick,
  onCheckboxClick,
}) => {
  const [hoveredSymbol, setHoveredSymbol] = useState<string | null>(null);

  const handleCheckboxClick = (event: any, stock: StockData) => {
    const checked = event.target.checked;
    onCheckboxClick(stock, checked); // Notify the parent component with the selected item
  };

  const handleItemClick = (stock: StockData) => {
    onItemClick(stock); // Notify the parent component with the selected item
  };

  const handleMouseEnter = (symbol: string) => {
    setHoveredSymbol(symbol);
  };

  const handleMouseLeave = () => {
    setHoveredSymbol(null);
  };

  return (
    <div>
      {data.map((stock) => {
        const isPositive = stock.sentiment.sentimentscore >= 6;
        const isNegative =
          stock.sentiment.sentimentscore < 5;
        const isNeutral = !isPositive && !isNegative;
        const isMature = stock.isMature;

        const priceColor =
          hoveredSymbol === stock.symbol
            ? "#f0f0f0"
            : isPositive
            ? "#36b23d"
            : isNegative
            ? "#d62d20"
            : isNeutral
            ? "#007bff"
            : "transparent";

        const tileBackgroundColor = isMature ? "#FFD700" : priceColor;

        return (
          <div
            className="tile"
            key={stock.symbol}
            style={{
              ...tileStyle,
              backgroundColor: tileBackgroundColor,
            }}
            onMouseEnter={() => handleMouseEnter(stock.symbol)}
            onMouseLeave={handleMouseLeave}
          >
            <div><center><StockChartTile data = {stock.candles}/></center></div>
            <div>
              <input
                checked={stock.isSelected}
                type="checkbox"
                onChange={(event) => handleCheckboxClick(event, stock)}
                style={{ width: "30px", height: "30px" }}
              />
            </div>
            <div onClick={() => handleItemClick(stock)}>
              <div style={symbolStyle}>{stock.symbol}</div>
              <div style={glyphs}>
                {stock.tsx === "POSITIVE" ? "📈" : "📉"}
                {stock.current_recommendation.toUpperCase() === "CATCH"
                  ? "💰"
                  : ""}
                {stock.current_recommendation.toUpperCase() === "THROW"
                  ? "🔥"
                  : ""}
                {stock.sentiment.optimism > 5 ? "😃" : ""}
                {stock.secSentimentScore * 100 > 50 ? "👔" : "☠️"}
              </div>
              <div style={dateStyle}>{stock.date}</div>
              <div style={priceStyle}>{`Entry: ${stock.price}`}</div>
              <div style={priceStyle}>{`Current: ${stock.current_price}`}</div>
              <div style={dateStyle}>{`SEC: ${Math.round(
                stock.secSentimentScore * 100
              )}%`}</div>
              <div style={dateStyle}>
                Cicle:{" "}
                {Math.round(
                  (stock.days_since_earnings /
                    (stock.days_until_earnings + stock.days_since_earnings)) *
                    100
                )}
                %
              </div>
              <div style={dateStyle}>EPS: {stock.eps_sentiment}</div>
              {stock.sentiment.sentimentscore && (
                <div>
              <div style={dateStyle}>Sentiment: {stock.sentiment.sentimentscore}</div>
              <div style={dateStyle}>Growth: {stock.sentiment.growth}</div>
              <div style={keywordStyle}>{'"' + stock.sentiment.challenges?.join('", "') + '"'}</div>
              </div>
              )}
              
            </div>
          </div>
        );
      })}
    </div>
  );
};

const tileStyle: React.CSSProperties = {
  display: "inline-block",
  border: "1px solid #555" /* Darker border color */,
  borderRadius: "5px",
  padding: "10px",
  margin: "10px",
  textAlign: "center",
};

const symbolStyle: React.CSSProperties = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#fff" /* Text color */,
};

const glyphs: React.CSSProperties = {
  fontSize: "20px",
  fontWeight: "bold",
  color: "#fff" /* Text color */,
};

const dateStyle: React.CSSProperties = {
  fontSize: "16px",
  marginTop: "5px",
  color: "#ccc" /* Lighter text color */,
};

const keywordStyle: React.CSSProperties = {
  fontSize: "11px",
  marginTop: "5px",
  color: "#fff" /* Lighter text color */,
};

const priceStyle: React.CSSProperties = {
  fontSize: "20px",
  marginTop: "5px",
  color: "#fff" /* Text color */,
};

export default StockDataDisplay;
